<template>
    <filter-list
        v-model="list"
        :sortKey="sortKey"
        :hasAction="false"
        ref="list"
        url="/successCase/all"
        :filters="filters"
        @init="init"
    >
        <template v-for="item in list">
            <success-patent-row :key="item.id" :info="item"></success-patent-row>
        </template>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import SuccessPatentRow from '../../components/list/SuccessPatentRow.vue';
import { moneyOptions, mode3Options } from '../../utils/variables';
import dayjs from 'dayjs';
export default {
    components: { FilterList, SuccessPatentRow },
    data() {
        return {
            moneyOptions,
            mode3Options,
            fieldOptions: [],
            list: [],
            sortKey: [
                // {
                //     name: '成交时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '成交价格',
                //     key: 'soldPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            return [
                {
                    name: '所属领域',
                    key: 'settingId',
                    list: [...this.fieldOptions]
                },
                {
                    name: '交易方式',
                    key: 'mode',
                    list: [...this.mode3Options]
                }
                // {
                //     name: '成交价格',
                //     key: 'soldPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '成交时间',
                //     key: 'soldAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        }
    },
    methods: {
        getTimeBefor(lng = -1, type = 'w') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(',');
        },
        getTimeOnem(lng = -1, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(',');
        },
        getTimeTom(lng = -2, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(',');
        },
        getTimeTherrm(lng = -3, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(',');
        },
        getTimeTherrms(lng = -10, type = 'M') {
            return [dayjs().add(lng, type).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(',');
        },
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 1 })
                .then(res => {
                    if (res.length > 0) {
                        this.fieldOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
